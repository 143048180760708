import React, { useEffect } from "react";
import Image from "next/image";
import { Trans } from "next-i18next";

import {
  APP_BUILD_INFO,
  IS_UNDER_PRODUCTION,
} from "@sellernote/_shared/src/constants";
import { formatDate } from "@sellernote/_shared/src/utils/common/date";
import { useCheckIsMobile } from "@sellernote/_shared/src/utils/common/hook";
import { trackPageByNaver } from "@sellernote/_shared/src/utils/common/trackingForMarketing";

import Logo from "../Logo";
import CompanyContactInfo from "./CompanyContactInfo";
import MainMenu from "./MainMenu";
import SalesMangerInfo from "./SalesMangerInfo";
import SnsList from "./SnsList";
import SupportAndTermsMenu from "./SupportAndTermsMenu";
import useMenuList from "./useMenuList";
import Styled from "./index.styles";

export default function Footer({ paddingBottom }: { paddingBottom?: number }) {
  const { isMobile } = useCheckIsMobile();

  /**
   * 메뉴 목록
   */
  const menuList = useMenuList();

  useEffect(() => {
    trackPageByNaver();
  }, []);

  return (
    <Styled.footerContainer paddingBottom={paddingBottom}>
      <Styled.footerMainWrapper>
        <Styled.footerMainContainer>
          <Styled.leftContainer>
            <Logo type="footer" />

            <CompanyContactInfo />

            <SalesMangerInfo />
          </Styled.leftContainer>

          <MainMenu menuList={menuList} />
        </Styled.footerMainContainer>
      </Styled.footerMainWrapper>

      <Styled.footerBottomContainer>
        <Styled.leftContainer>
          <SnsList />

          {!isMobile && (
            <div className="desktop-aeo-footer">
              <Image
                src="/assets/images/layout/aeo-footer.png"
                width={46}
                height={44}
                alt="AEO"
              />
            </div>
          )}
        </Styled.leftContainer>

        <Styled.footerBottomRightContainer>
          <SupportAndTermsMenu />

          {isMobile && (
            <div className="mobile-aeo-footer">
              <Image
                src="/assets/images/layout/aeo-footer.png"
                width={46}
                height={44}
                alt="AEO"
              />
            </div>
          )}

          <div className="company-info">
            {isMobile ? (
              <Trans i18nKey="common-new:푸터_회사정보_모바일" />
            ) : (
              <Trans i18nKey="common-new:푸터_회사정보_데스크탑" />
            )}
            <br />
            <Trans i18nKey="common-new:푸터_저작권" />
          </div>
        </Styled.footerBottomRightContainer>
      </Styled.footerBottomContainer>

      {APP_BUILD_INFO && (
        <div className="app-build-info">
          <div className={`built-at ${IS_UNDER_PRODUCTION ? "hidden" : ""}`}>
            {`빌드: ${formatDate({
              date: APP_BUILD_INFO.builtAt,
              type: "YY_MM_DD_HH_mm_ss",
            })}`}
          </div>
          <div className="hidden">{APP_BUILD_INFO.gitBranch}</div>
          <div className="hidden">{APP_BUILD_INFO.gitCommitSha}</div>
        </div>
      )}
    </Styled.footerContainer>
  );
}
